<template>
  <v-card>
    <Loader
      v-if="loading"
      :key="generateUniqueId()"
      :loader-state="loading"
    ></Loader>

    <v-card-title>
      Détails des paramètres pour la route : {{ metadataParameter ? metadataParameter.route : '' }}
    </v-card-title>

    <v-card-text>
      <v-form
        v-model="valid"
        class="multi-col-validation mt-5"
        @submit.prevent="checkForm"
      >
        <p v-if="errors && errors.length">
          <v-alert
            v-for="(error, index) in errors"
            :key="index"
            text
            dense
            type="error"
          >
            {{ error.title }}
          </v-alert>
        </p>

        <Snackbar
          v-if="successMessage"
          :key="generateUniqueId()"
          :snackbar-state="snackbar"
          :success-message="successMessage"
        ></Snackbar>

        <v-row>
          <v-col
            cols="10"
            md="10"
          >
            <v-select
              v-model="route"
              :items="availableRoutes"
              label="Page"
              class="mt-5"
              outlined
              persistent-hint
              disabled
            ></v-select>

            <div
              v-for="(parameter, parameterIndex) in parameters"
              :key="parameterIndex"
            >
              <v-divider class="v-divider-entity mt-2 mb-5"></v-divider>

              <v-text-field
                v-model="parameter.name"
                label="Nom"
                class="mb-3"
                outlined
                dense
                placeholder="Nom"
                hide-details
              ></v-text-field>

              <v-text-field
                v-model="parameter.method"
                label="Méthode"
                class="mb-3"
                outlined
                dense
                placeholder="Méthode"
                hide-details
              ></v-text-field>

              <v-text-field
                v-model="parameter.description"
                label="Description"
                class="mb-3"
                outlined
                dense
                placeholder="Description"
                hide-details
              ></v-text-field>

              <v-btn
                x-small
                color="error"
                class="mb-2"
                @click="removeParameterForm(parameterIndex)"
              >
                <v-icon
                  small
                  dark
                >
                  {{ icons.mdiMinusCircleOutline }}
                </v-icon>
                <span class="ml-2">Suppression du paramètre</span>
              </v-btn>
            </div>

            <v-btn
              x-small
              color="primary"
              class="mt-5"
              @click="addParameterForm()"
            >
              <v-icon
                small
                dark
              >
                {{ icons.mdiPlusCircleOutline }}
              </v-icon>
              <span class="ml-2">Ajout de paramètre</span>
            </v-btn>
          </v-col>
        </v-row>

        <v-row>
          <v-col
            cols="12"
            md="6"
          >
            <v-btn
              class="mt-5 mr-2"
              color="primary"
              :disabled="!valid"
              type="submit"
            >
              Valider
            </v-btn>

            <v-btn
              class="mt-5"
              text
              :to="{ name: 'metadataParameterList' }"
            >
              Retour
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import Vue from 'vue'
import { mdiPlusCircleOutline, mdiMinusCircleOutline } from '@mdi/js'
import config from '../../../config'
import Snackbar from '@/components/Common/Snackbar'
import Loader from '@/components/Common/Loader'

export default {
  components: {
    Snackbar,
    Loader
  },
  data () {
    return {
      valid: false,
      errors: [],
      snackbar: false,
      successMessage: null,
      loading: false,
      metadataParameter: null,
      route: null,
      availableRoutes: [],
      parameters: [],
      icons: {
        mdiPlusCircleOutline,
        mdiMinusCircleOutline
      }
    }
  },
  created () {
    this.getRoutes()
    this.getMetadataParameter()
  },
  methods: {
    checkForm () {
      this.errors = []

      if (this.valid) {
        this.putMetadataParameter()
      }
    },
    getMetadataParameter () {
      const metadataParameterRoute = this.$route.params.metadataParameterRoute

      this.valid = false

      Vue.prototype.$http
        .get(config.apiUrl + '/api/seo/metadata-parameters/' + metadataParameterRoute, {
          headers: {
            Authorization: 'Bearer ' + this.$store.getters.getToken
          }
        })
        .then(response => {
          if (response.status === 200) {
            this.metadataParameter = response.data
            this.route = this.metadataParameter.route

            for (const [currentParameterId] of Object.entries(this.metadataParameter.parameters)) {
              this.parameters.push(this.metadataParameter.parameters[currentParameterId])
            }

            this.errors = []
          }
        })
        .catch(e => {
          const response = e.response

          this.successMessage = null

          if (response.status === 400 || response.status === 404) {
            const data = response.data
            this.errors = data.violations
          }

          this.valid = true
        })
    },
    putMetadataParameter () {
      this.valid = false
      this.loading = true

      const data = {
        route: this.route,
        parameters: this.parameters
      }

      Vue.prototype.$http
        .put(config.apiUrl + '/api/seo/metadata-parameters/' + this.metadataParameter.id, data, {
          headers: {
            Authorization: 'Bearer ' + this.$store.getters.getToken
          }
        })
        .then(response => {
          if (response.status === 200) {
            this.snackbar = true
            this.successMessage = 'Paramètres de métadonnées mis à jour avec succès.'
            this.valid = true
          }

          this.loading = false
        })
        .catch(e => {
          const response = e.response

          if (response.status === 400 || response.status === 404) {
            const data = response.data
            this.errors = data.violations
          }

          this.valid = true
          this.loading = false
        })
    },
    getRoutes () {
      Vue.prototype.$http
        .get(config.apiUrl + '/api/routes', {
          headers: {
            Authorization: 'Bearer ' + this.$store.getters.getToken
          }
        })
        .then(response => {
          if (response.status === 200) {
            const data = response.data

            if (data) {
              for (const [currentValue, currentLabel] of Object.entries(data)) {
                this.availableRoutes.push({
                  text: currentLabel,
                  value: currentValue
                })
              }
            }
          }
        })
        .catch(e => {
          const response = e.response

          if (response.status === 400 || response.status === 404) {
            const data = response.data
            this.errors = data.violations
          }

          this.valid = true
        })
    },
    addParameterForm () {
      this.parameters.push({
        name: null,
        method: null,
        description: null
      })
    },
    removeParameterForm (parameterIndex) {
      this.parameters.splice(parameterIndex, 1)
    }
  }
}
</script>
